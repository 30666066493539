$(document).ready(function () {
    /**
     * Solution to enable links on dropdowns, the link will only be triggered
     * if the dropdown is visible. On touch devices you will need to double
     * click on a dropdown, the first click will open the menu.
     */
    function navbarPointerOver($element) {
        if($('.navbar-toggler').is(':hidden') && !$element.hasClass('open')) {
            $element.parent().parent().find('li').removeClass('show');
            $element.addClass('show');
            $element.find('> .dropdown-toggle').attr("aria-expanded", "true");
            $element.find('> .dropdown-menu').addClass('show');
        }
    }
    function navbarPointerLeave($element) {
        if ($('.navbar-toggler').is(':hidden')) {
            $element.removeClass('show');
            $element.find('> .dropdown-toggle').attr("aria-expanded", "false");
            $element.find('> .dropdown-menu').removeClass('show');
        }
    }
    $(document).on('pointerover', '.dropdown-hover', function (e) {
        if (e.originalEvent.pointerType === "mouse") {
            navbarPointerOver($(this));
        }
    });
    $(document).on('mouseenter', '.dropdown-hover', function () {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            navbarPointerOver($(this));
        }
    });
    $(document).on('pointerleave', '.dropdown-hover', function (e) {
        if (e.originalEvent.pointerType === "mouse") {
            navbarPointerLeave($(this));
        }
    });
    $(document).on('mouseleave', '.dropdown-hover', function () {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            navbarPointerLeave($(this));
        }
    });
    $(document).on('click', '.dropdown-toggle', function(e) {
        if ($(this).parents().hasClass('dropdown-hover') && !$(this).parents().hasClass('show')) {
            $(this).parents().parent().find('.dropdown-hover').removeClass('show');
            $(this).parents().parent().find('.dropdown-hover').find('> .dropdown-toggle').attr("aria-expanded", "false");
            $(this).parents().parent().find('.dropdown-hover').find('> .dropdown-menu').removeClass('show');
            $(this).parents().addClass('show');
            $(this).parent().find('> .dropdown-toggle').attr("aria-expanded", "true");
            $(this).parents().find('> .dropdown-menu').addClass('show');
            e.stopImmediatePropagation();
            e.preventDefault();
            return false;
        }
    });

    $('.list-unstyled.collapse').on('show.bs.collapse', function () {
        $(this).parent('li').addClass('open');
    });

    $('.list-unstyled.collapse').on('hide.bs.collapse', function () {
        $(this).parents('li').removeClass('open');
    });

    JSH.responsiveEvent(function () {
        if(JSH.viewport().width < 992){
            if($('.navbar-toggler').hasClass('show')){
                var screenHeight = JSH.viewport().height - 128;
                $('#navbarMain').css('height', screenHeight+'px');
            }
        }
    });

    $('.navbar-toggler').click(function(){
        if($(this).hasClass('show')){
            $(this).removeClass('show');
            $('#navbarMain').removeClass('show').css('height', '0');
            $('.main-header').removeClass('show');
            $('html').removeClass('main-navbar-open');

        } else{
            $(this).addClass('show');
            var screenHeight = JSH.viewport().height - 128;
            $('#navbarMain').addClass('show').css('height', screenHeight+'px');
            $('.main-header').addClass('show');
            $('html').addClass('main-navbar-open');
        }
    });
});