$(function() {
    $('html, body').scrollTop(0);
    //
    // Smooth Sroll
    //
    $('a[href*="#"]:not([href$="#"])').click(function() {
        //console.log('link clicked');
        if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'')
            && location.hostname === this.hostname
            && $(this).data('toggle') === undefined
            && $(this).data('slide') === undefined) {
            var $target = $(this.hash);
            $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
            if ($target.length) {
                var targetOffset = $target.offset().top;
                var header = $('.main-header.fixed-top');
                if(header.length && targetOffset !== 0){
                    targetOffset -= header.outerHeight();
                }
                $('html,body').animate({scrollTop: targetOffset}, 500);
                return false;
            }
        }
    });

    if (window.location.hash) {
        var $target = $(window.location.hash);
        if ($target.length) {
            var targetOffset = $target.offset().top;
            var header = $('.main-header.fixed-top');
            if(header.length && targetOffset !== 0){
                targetOffset -= header.outerHeight();
            }
            $('html,body').animate({scrollTop: targetOffset}, 500, 'linear', function() {
                // Animation complete. Adjust Offset if Height changed during Scrolling.
                var newOffset = $target.offset().top;
                if (header.length && targetOffset !== 0) {
                    newOffset -= header.outerHeight();
                }
                if (targetOffset !== newOffset){
                    $('html,body').animate({scrollTop: newOffset}, 300);
                }
            });
            return false;
        }
    }
});
