$(function() {

    if($('.mfp-image').length > 0 && JSH.getLanguage() == 'de') {
        //Translate magnific popup
        $.extend(true, $.magnificPopup.defaults, {
            tClose: 'Schließen (Esc)', // Alt text on close button
            tLoading: 'Lädt...', // Text that is displayed during loading. Can contain %curr% and %total% keys
            gallery: {
                tPrev: 'Zurück (linke Pfeiltaste)', // Alt text on left arrow
                tNext: 'Weiter (rechte Pfeiltaste)', // Alt text on right arrow
                tCounter: '%curr% von %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">Das Bild</a> konnte nicht geladen werden.' // Error message when image could not be loaded
            },
            ajax: {
                tError: '<a href="%url%">Der Inhalt</a> konnte nicht geladen werden.' // Error message when ajax request failed
            }
        });
    }

    $('.mfp-gallery').magnificPopup({
        delegate: 'a.mfp-image',
        type: 'image',
        closeOnContentClick: false,
        closeBtnInside: false,
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        image: {
            verticalFit: true,
            titleSrc: function(item) {
                return item.el.attr('title');
            }
        },
        gallery: {
            enabled: true
        },
        zoom: {
            enabled: true,
            duration: 300, // don't forget to change the duration also in CSS
            opener: function(element) {
                return element.find('img');
            }
        }
    });
});
