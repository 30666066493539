
$(function() {
    $('html').removeClass('no-js');
});

$(window).on('load', function() {
    initAnimateContent();
    $(window).on('scroll resize', function () {
        animateContent();
    });
    //open product accordions should have equal height
    if(JSH.isIe()) {
        $('html').addClass('is-ie');
    }else {
        $('.product-accordion').on('shown.bs.collapse', function (e) {
            $(this).parents('.product-accordion-col').addClass('d-flex');
        });
        $('.product-accordion').on('hide.bs.collapse', function (e) {
            $(this).parents('.product-accordion-col').removeClass('d-flex');
        });
    }

    JSH.responsiveEvent(function () {
        if(JSH.viewport().width >= 768){
            $(".lazyload-md").each(function() {
                var $element = $(this);
                $element.css('background-image', 'url(' + $element.data('bg') + ')');
                $element.removeClass('lazyload-md').addClass('lazyloaded');
            });
        }
    });

    $('.header-caption').addClass('animated');
});

function initAnimateContent() {
    $(".frame-default:not('.off-screen')").each(function() {
        if(JSH.isBottomScrolledIntoView($(this), true)) {
            $(this).addClass('animated');
        }else {
            $(this).addClass('off-screen');
        }
    });
}

function animateContent() {
    //animate all Items with Classname "animation-item" once
    delayTime = 0;
    $(".frame-default:not('.animated')").each(function() {
        var $element = $(this);
        if(JSH.isBottomScrolledIntoView($(this), true)) {
            delayTime = delayTime + 200;
            if(delayTime > 600) {
                delayTime = 0;
            }
            setTimeout(function(){
                $element.addClass('animated');
            }, delayTime);
        }else {
            delayTime = 0;
        }
    });
}

